<template>
  <PageSection :section-title="sectionTitle">
    <template #body>
      <div class="categories-section-main">
        <div class="categories-grid">
          <nuxt-link
            v-for="(category, index) in displayedCategories.slice(0, 6)"
            :key="index"
            ref="categoriesItems"
            :to="localePath(getCatLink(category))"
            class="category-item"
            @keydown.native="
              keyboardNavigate($event, index, displayedCategories)
            "
          >
            <nuxt-img
              class="category-image bg-ms-white"
              :src="getCategoryImage(category)"
              :alt="category.meta_description"
              format="webp"
              sizes="xl:712px md:344px"
            />
            <span class="category-name">{{ category.name }}</span>
          </nuxt-link>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useUiHelpers } from '~/composables';
import { keyboardNavigation } from '~/utilities';
import PageSection from '../PageSection.vue';

export default defineComponent({
  components: { PageSection },
  props: {
    sectionTitle: {
      type: String,
      default: 'shop wall art',
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();
    const allCategories = ref([]);
    const loadCategories = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      allCategories.value = categories.categories;
    };

    const displayedCategories = computed(() => {
      const rawCategories = allCategories.value;
      return rawCategories?.children ?? [];
    });

    const getCategoryImage = (category) => (category?.image ? category.image : 'defaults/home-mag-image.png');

    onMounted(() => {
      loadCategories();
    });
    return {
      displayedCategories, loadCategories, getCatLink, getCategoryImage,
    };
  },
  methods: {
    keyboardNavigate(event, index, table) {
      const actions = {
        37: () => {
          if (index !== 0) {
            index -= 1;
            this.$refs.categoriesItems[index].$el.focus();
          }
        },
        38: () => {
          const half = table.length / 2;
          if (index >= half) {
            index -= half;
            this.$refs.categoriesItems[index].$el.focus();
          }
        },
        39: () => {
          if (index !== table.length - 1) {
            index += 1;
            this.$refs.categoriesItems[index].$el.focus();
          }
        },
        40: () => {
          const half = table.length / 2;
          if (index < half) {
            index += half;
            this.$refs.categoriesItems[index].$el.focus();
          }
        },
      };
      keyboardNavigation(event, index, table, actions);
    },
  },
});
</script>

<style scoped lang="scss">
.categories-section-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  @include responsive-styles(padding, 0, 0, 0, 0 67px);
  .categories-grid {
    // max-width: 1200px;
    width: 100%;
    justify-items: center;
    display: grid;
    @include responsive-styles(
      grid-template-columns,
      1fr 1fr,
      1fr 1fr,
      1fr 1fr 1fr,
      1fr 1fr 1fr
    );
    @include responsive-styles(
      grid-template-rows,
      1fr 1fr 1fr,
      1fr 1fr 1fr,
      1fr 1fr,
      1fr 1fr
    );
    @include responsive-styles(row-gap, 20px, 20px, 50px, 80px);
    @include responsive-styles(column-gap, 13px, 13px, 45px, 50px);
    .category-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .category-image {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        transition: transform 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
        object-fit: cover;
      }
      .category-name {
        @include responsive-styles(font-size, 20px, 20px, 24px, 24px);
        margin-top: 10px;
        color: $color-secondary;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 133%;
      }
      &:hover {
        .category-image {
            transform: scale3d(1.05, 1.05, 1);
        }
        .category-name {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
